import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import Blockchain from './Blockchain';
import Ios from './Ios';
import Android from './Android';
import AI from './AI';
import Fullstack from './Fullstack';
import Video from './Video';
import { media } from '../../../globalStyles';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';

const PlatformItemsRow = styled.div`
	display: flex;
	justify-content: ${({ align }) => (align ? align : 'space-between')};
	position: relative;
	${media.maxXl`
		flex-direction: column;
		justify-content: flex-start;
		max-width: 80%;
		margin: 0 auto;
	`};
	@media (max-width: 650px) {
		max-width: 100%;
	}
`;
const PlatformsInner = styled.div`
	&&& {
		margin: 0 auto 85px;
		max-width: 1600px;
		padding: 0 30px;
		${media.xxl`
			padding: 0 15px;
		`}
		${media.maxSm`
			padding: 0;
		`}
	}
`;
const InnovativePlatforms = () => (
	<ContentSection
		fluid
		title="Innovative Platforms That Make The Impossible Possible"
		subtitle="Reenergize your business by creating an unforgettable online presence. "
		seoTitle={'h2'}
	>
		<PlatformsInner>
			<PlatformItemsRow>
				<Blockchain />
				<Ios />
			</PlatformItemsRow>
			<PlatformItemsRow align="flex-end">
				<Video />
				<Android />
			</PlatformItemsRow>
			<PlatformItemsRow>
				<AI />
				<Fullstack />
			</PlatformItemsRow>
		</PlatformsInner>
		<CenterAnimatedButton
			gaLabel="home-page Discover More"
			animateOut="fadeOut"
			animateIn="fadeIn"
			linkTo="/technologies"
		/>
	</ContentSection>
);

export default InnovativePlatforms;
