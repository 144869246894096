import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import { graphql, useStaticQuery } from 'gatsby';
// Components
import Cell from './Cell';
// Style
import { breakpoints, media } from '../../../globalStyles';
import programmersDB from './programmers.json';
//----------------------------------------------------------------------------------------------------------------------
const Wrapper = styled.div`
	display: flex;
	//max-width: 1840px;
	flex-wrap: wrap;
	margin: 53px auto 113px;
	${media.maxMd`
		display: none;
	`}
`;
const ProgrammersBlock = styled.div`
	.slick-slider {
		margin-bottom: 85px;
	}

	.slick-track {
		display: flex;
	}

	${media.minMd`
		.slick-slider {
				display: none;
		}
	`}
`;

const sliderSettings = {
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: false,
	autoplaySpeed: 1500,
	centerMode: true,
	centerPadding: '50px',
	arrows: false,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				swipeToSlide: true,
				slidesToShow: 3,
				variableWidth: true,
			},
		},
		// {
		// 	breakpoint: 480,
		// 	settings: {
		// 		// mobileFirst: true,
		// 		arrows: false,
		// 		centerMode: true,
		// 		swipeToSlide: true,
		// 		centerPadding: '40px',
		// 		slidesToShow: 1,
		// 		variableWidth: true,
		// 	},
		// },
	],
};

class ProgrammersChessboard extends React.Component {
	state = {
		isXl: false,
		showSlider: false,
	};

	componentDidMount() {
		this.mQuery = window.matchMedia(breakpoints.laptop);
		this.setState({ isXl: this.mQuery.matches });
		this.mQuery.addListener(this.handleMediaQuery);
		setTimeout(() => this.setState({ showSlider: true }));
	}

	componentWillUnmount() {
		this.mQuery.removeListener(this.handleMediaQuery);
	}

	handleMediaQuery = event => {
		this.setState({ isXl: event.matches });
	};

	render() {
		const cells = this.props.programmers.map((programmer, index) => {
			const reverse = this.state.isXl
				? (index > 2 && index < 6) || (index > 8 && index < 12)
				: index > 3 && index < 8;

			const bottomRow = this.state.isXl
				? index > 8 && index < 12
				: index > 7 && index < 12;
			return (
				<Cell
					key={programmer.name}
					programmer={programmer}
					reverse={reverse}
					index={index}
					bottomRow={bottomRow}
				/>
			);
		});
		return (
			<ProgrammersBlock>
				<Wrapper>{cells}</Wrapper>
				{this.state.showSlider && <Slider {...sliderSettings}>{cells}</Slider>}
			</ProgrammersBlock>
		);
	}
}

ProgrammersChessboard.propTypes = {
	programmers: PropTypes.array,
};

const ConnectedProgrammersChessboard = () => {
	const data = useStaticQuery(graphql`
		query {
			allFile(
				filter: {
					relativeDirectory: { eq: "team" }
					name: { regex: "/-exp$/" }
				}
			) {
				edges {
					node {
						name
						childImageSharp {
							# Specify the image processing specifications right in the query.
							# Makes it trivial to update as your page's design changes.
							fluid(maxWidth: 500, maxHeight: 750, quality: 100) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	`);
	const preparedData = Object.keys(programmersDB).map(programmerId => {
		const { node } = data.allFile.edges.find(({ node }) =>
			node.name.includes(programmerId)
		);
		return {
			...programmersDB[programmerId],
			image: node.childImageSharp.fluid,
		};
	});
	return <ProgrammersChessboard programmers={preparedData} />;
};

export default ConnectedProgrammersChessboard;
