import React from 'react';
import styled from 'styled-components';
import { media } from '../../../globalStyles';
import CoverImg from '../../_common/CoverImg';
import { graphql, useStaticQuery } from 'gatsby';

const SelfDecodeWrapper = styled.div`
	display: block;
	position: relative;
	height: 579px;
	${media.maxXl`
		height: 543px;
	`};
`;

const SelfDecode = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "self-decode.jpg" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return (
		<SelfDecodeWrapper>
			<CoverImg
				fluid={data.file.childImageSharp.fluid}
				title="selfdecode-cover"
				alt="selfdecode-cover"
			/>
		</SelfDecodeWrapper>
	);
};

export default SelfDecode;
