import React from 'react';
import styled from 'styled-components';
import boxSuite from '../../../assets/img/box-suite.png';
import homeHello from '../../../assets/img/homehello.png';
import s8property from '../../../assets/img/s8-property.png';
import moveria from '../../../assets/img/moveria.png';
import openDrive from '../../../assets/img/open-drive.png';
import pley from '../../../assets/img/pley.png';
import mobelio from '../../../assets/img/mobelio.png';
import ValuedClientsRow from './ValuedClientsRow';
import ValuedClientItem from './ValuedClientItem';
import ContentSection from '../../ContentSection';
import { media } from '../../../globalStyles';

const clients = {
	boxSuite: {
		name: 'boxSuite',
		img: boxSuite,
		text: 'An efficient, smart way to manage staff schedules, pay, & much more',
		alt: 'box suite logo',
	},
	homeHello: {
		name: 'homeHello',
		img: homeHello,
		text: 'Reliable, affordable, & convenient house cleaning service',
		alt: 'homehello logo',
	},
	s8property: {
		name: 's8property',
		img: s8property,
		text: 'Fully integrated commercial & residential real estate website',
		alt: 's8 property logo',
	},
	moveria: {
		name: 'moveria',
		img: moveria,
		text: 'Helping customers find deals for moving & relocation services',
		alt: 'moveria logo',
	},
	openDrive: {
		name: 'openDrive',
		img: openDrive,
		text: 'Unlimited cloud storage, backup & cloud content management system',
		alt: 'opendrive logo',
	},
	pley: {
		name: 'pley',
		img: pley,
		text: 'Monthly toy service that promotes learning & creativity',
		alt: 'pley logo',
	},
	mobelio: {
		name: 'mobelio',
		img: mobelio,
		text: 'A place where businesses tap into the power of mobile marketing',
		alt: 'mobelio logo',
	},
};
const StyledValuedClients = styled.div`
	background-color: #365277;
	margin-left: -15px;
	margin-right: -15px;
	padding: 99px 0 94px;
	overflow: hidden;

	@media (max-width: 1200px) {
		padding-right: 20px;
		padding-left: 20px;
	}

	${media.maxMd`
		padding-top: 50px;
		padding-bottom: 0;
	`}
	.clients-container {
		max-width: 1736px;
		margin: 0 auto;
	}
`;

const ValuedClients = () => {
	return (
		<ContentSection
			paddingBottom={0}
			paddingBottomSm={0}
			fluid
			title="Some of Our Valued Clients"
			subtitle="From the United States to the UK and Ukraine to Australia, we serve clients around the globe"
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<StyledValuedClients>
				<div className="clients-container">
					<ValuedClientsRow>
						<ValuedClientItem {...clients.boxSuite} />
						<ValuedClientItem {...clients.homeHello} />
						<ValuedClientItem visibleSm {...clients.s8property} />
						<ValuedClientItem visibleSm {...clients.moveria} />
						{/* <ValuedClientItem {...clients.moveria} /> */}
					</ValuedClientsRow>
					<ValuedClientsRow line={1} justifyContent="space-between">
						<ValuedClientItem {...clients.s8property} />
						<ValuedClientItem {...clients.moveria} />
						<ValuedClientItem {...clients.openDrive} />
					</ValuedClientsRow>
					<ValuedClientsRow line={2}>
						{/* <ValuedClientItem {...clients.openDrive} /> */}
						<ValuedClientItem visibleSm {...clients.openDrive} />
						<ValuedClientItem {...clients.pley} />
						<ValuedClientItem {...clients.mobelio} />
					</ValuedClientsRow>
				</div>
			</StyledValuedClients>
		</ContentSection>
	);
};

ValuedClients.propTypes = {};

export default ValuedClients;
