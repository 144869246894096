import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';
import ProjectItem from './ProjectItem';
import Nutrino from './Nutrino';
import SelfDecode from './SelfDecode';
import Ramble from './Ramble';
import { media } from '../../../globalStyles';

const StyledProjects = styled.div`
	display: flex;
	justify-content: space-around;
	max-width: 1586px;
	margin: 0 auto 113px;
	@media (max-width: 1125px) {
		flex-direction: column;
		align-items: center;
	}
	${media.maxMd`
		margin-bottom: 0;
	`}
`;
const Projects = () => {
	return (
		<ContentSection
			fluid
			title={`App Development Projects <br/> That Make Us Proud`}
			// subtitle="Spanning over a decade of programming and development projects"
			seoTitle={'h2'}
		>
			<StyledProjects>
				<ProjectItem
					name="SelfDecode"
					description="Personalized health reports based on DNA"
					linkTo="https://cases.pixelteh.com/selfdecode/"
				>
					<SelfDecode />
				</ProjectItem>
				<ProjectItem
					name="Nutrino"
					description="An advanced platform promoting health and wellness"
					linkTo="https://cases.pixelteh.com/nutrino-2/"
				>
					<Nutrino />
				</ProjectItem>
				<ProjectItem
					name="Ramble Chat"
					description="A chat that works with all social networks"
					linkTo="https://cases.pixelteh.com/ramble/"
				>
					<Ramble />
				</ProjectItem>
			</StyledProjects>
			<CenterAnimatedButton
				linkTo="/portfolio"
				gaLabel="home-page View more cases"
			>
				View more cases
			</CenterAnimatedButton>
		</ContentSection>
	);
};

export default Projects;
