import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import CoverImg from '../../_common/CoverImg';
import H5 from '../../_common/H5';
// import nutrinoBg from '../../../assets/img/nutrino-bg.png';
// import pinapple from '../../../assets/img/pinapple.png';
import { colors, fonts, media } from '../../../globalStyles';

const StyledNutrino = styled.div`
	border: 4px solid #77c277;
	padding: 84px 46px 29px 42px;
	position: relative;
	.img-wrapper {
		text-align: center;
		max-width: 224px;
		margin: 0 auto;
		.pinapple {
			width: 100%;
		}
	}
	hr {
		width: 166px;
		height: 4px;
		background: #77c277;
		margin-top: 60px;
		margin-bottom: 63px;
		border: none;
	}
	${H5} {
		font-size: 24px;
		line-height: 38px;
		padding: 0 10px 0 0px;
		text-align: center;
		text-decoration: none;
		color: ${colors.blackTitle};
		font-family: ${fonts.openSans};
		margin-bottom: 10px;
		b {
			font-family: ${fonts.openSansExtraBold};
		}
	}
	hr,
	${H5} {
		position: relative;
		z-index: 1;
	}
	${media.maxXl`
		padding: 48px 25px 29px 25px;
		height: 543px;
	`}
	@media(min-width: 1126px) and (max-width: 1242px) {
		${H5} {
			font-size: 20px;
		}
	}
	@media (max-width: 389px) {
		${H5} {
			font-size: 18px;
			line-height: 30px;
		}
	}
`;

const Nutrino = () => {
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { name: { regex: "/nutrino-home/" } }) {
				edges {
					node {
						name
						...ChildImageSharpFluid
					}
				}
			}
		}
	`);
	const bgImg = data.allFile.edges.filter(
		i => i.node.name === 'nutrino-home-bg'
	)[0];
	const pineapple = data.allFile.edges.filter(
		i => i.node.name === 'nutrino-home-pinapple'
	)[0];
	return (
		<StyledNutrino>
			<CoverImg
				fluid={bgImg.node.childImageSharp.fluid}
				title="nutrino-cover"
			/>
			<div className="img-wrapper">
				<Img
					fluid={pineapple.node.childImageSharp.fluid}
					title="nutrino-pineapple"
					alt="nutrino-pineapple"
					className="pinapple"
				/>
			</div>
			<hr />
			<H5>
				YOU LOVE FOOD,
				<br />
				FIND OUT
				<b>
					{' '}
					WHICH FOODS
					<br />
					LOVE YOU BACK
				</b>
			</H5>
		</StyledNutrino>
	);
};

export default Nutrino;
