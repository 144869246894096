import React from 'react';
import styled from 'styled-components';
import H1 from '../_common/H1';
import H2 from '../_common/H2';
import { fonts } from '../../globalStyles';

export const StyledPreloader = styled.div`
	position: fixed;
	background-color: rgb(36, 56, 99);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	font-family: ${fonts.loveloBlack};
	z-index: 99;
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 1;
	transition: 1s opacity;
	.lettersLoaderItem:nth-child(1) {
		animation: animate-overlay-letter-1 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-1 {
		0% {
			opacity: 1;
		}
		0% {
			opacity: 1;
		}
		12.5% {
			opacity: 0;
		}
		25% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(2) {
		animation: animate-overlay-letter-2 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-2 {
		0% {
			opacity: 1;
		}
		12.5% {
			opacity: 1;
		}
		25% {
			opacity: 0;
		}
		37.5% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(3) {
		animation: animate-overlay-letter-3 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-3 {
		0% {
			opacity: 1;
		}
		25% {
			opacity: 1;
		}
		37.5% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(4) {
		animation: animate-overlay-letter-4 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-4 {
		0% {
			opacity: 1;
		}
		37.5% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		62.5% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(5) {
		animation: animate-overlay-letter-5 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-5 {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}
		62.5% {
			opacity: 0;
		}
		75% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(6) {
		animation: animate-overlay-letter-6 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-6 {
		0% {
			opacity: 1;
		}
		62.5% {
			opacity: 1;
		}
		75% {
			opacity: 0;
		}
		87.5% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(7) {
		animation: animate-overlay-letter-7 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-7 {
		0% {
			opacity: 1;
		}
		75% {
			opacity: 1;
		}
		87.5% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.lettersLoaderItem:nth-child(8) {
		animation: animate-overlay-letter-8 4000ms linear infinite;
	}
	@keyframes animate-overlay-letter-8 {
		0% {
			opacity: 1;
		}
		87.5% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
		0% {
			opacity: 1;
		}
	}

	${H1} {
		color: white;
		font-size: 100px;
		font-weight: 500;
		line-height: 120px;
	}
	${H2} {
		font: 400 22px/22px ${fonts.loveloBlack};
		display: block;
		color: white;
		text-transform: uppercase;
	}
`;
const Preloader = () => (
	<StyledPreloader className="preloader">
		<H1>
			<span className="lettersLoaderItem">P</span>
			<span className="lettersLoaderItem">I</span>
			<span className="lettersLoaderItem">X</span>
			<span className="lettersLoaderItem">E</span>
			<span className="lettersLoaderItem">L</span>
			<span className="lettersLoaderItem">T</span>
			<span className="lettersLoaderItem">E</span>
			<span className="lettersLoaderItem">H</span>
		</H1>
		<H2>Engineering the future of immersive technology</H2>
	</StyledPreloader>
);

export default Preloader;
