import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../globalStyles';

const StyledScale = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 118px;
	.scale,
	.scale-fullfilled {
		position: absolute;
		left: 0;
		right: 0;
		height: 12px;
	}
	.scale {
		background-color: ${colors.greyScale};
		top: 34px;
		z-index: 1;
	}
	.scale-fullfilled {
		top: 0;
		background-color: ${colors.red};
		max-width: 0;
		transition: max-width 0.3s;
		z-index: 2;
		&.year- {
			&2008 {
				max-width: 25%;
			}
			&2011 {
				max-width: 50%;
			}
			&2015 {
				max-width: 75%;
			}
			&2017 {
				max-width: 100%;
			}
		}
	}
	.scale-item {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 25px 0;
		background: none;
		outline: none;
		border: none;
		cursor: pointer;
		z-index: 3;
		&.active {
			.active-year-label {
				display: block;
			}
		}
		.scale-item-delimiter {
			background-color: ${colors.red};
			height: 30px;
			width: 11px;
			border-radius: 4px;
			z-index: 3;
		}
		.active-year-label {
			position: absolute;
			top: 0;
			left: -13px;
			font-size: 16px;
			color: ${colors.red};
			white-space: nowrap;
			display: none;
		}
		.year-label {
			position: absolute;
			bottom: 0;
			left: -13px;
			font-size: 16px;
			color: ${colors.blackTitle};
			white-space: nowrap;
		}
	}
`;

const YearsScale = ({ years, currentYear, onChangeYear }) => {
	return (
		<StyledScale>
			{years.map(year => (
				<button
					type="button"
					key={year}
					onClick={() => onChangeYear(year)}
					className={`scale-item ${currentYear === year ? 'active' : ''}`}
				>
					<span className="active-year-label">{year}</span>
					<span className="scale-item-delimiter" />
					<span className="year-label">{year}</span>
				</button>
			))}
			<div className="scale">
				<div className={`scale-fullfilled year-${currentYear}`} />
			</div>
		</StyledScale>
	);
};

YearsScale.propTypes = {
	years: PropTypes.array,
	currentYear: PropTypes.string,
	onChangeYear: PropTypes.func.isRequired,
};

export default YearsScale;
