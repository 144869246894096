import React from 'react';
import styled from 'styled-components';
import line5 from '../../../assets/svg/line-5.svg';
import Title from './Title';
import Text from './Text';
import { media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';
import StyledPlatformItem from './PlatformItem';

const StyledFullstack = styled(StyledPlatformItem)`
	width: 315px;
	margin-top: 142px;
	${media.xxl`
		margin-top: 200px;
		width: 468px;
	`}
	.svg-container {
		//right: 100%;
		//top: -117px;
		//width: 213px;
		padding-bottom: 0;
		top: -161px;
		width: 298px;
		right: 94%;
	}
`;
const Fullstack = () => (
	<StyledFullstack>
		<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
			<Title Line={line5}>Full Stack Development</Title>
			<Text linkTo="/technologies-full-stack">
				Leverage your business by fueling your applications with our full stack
				developers. We use the latest technologies to develop and test your
				frontend and backend code so that your apps work perfectly.
			</Text>
		</AnimatedOnScroll>
	</StyledFullstack>
);

export default Fullstack;
