import React from 'react';
import PropTypes from 'prop-types';

import PageLayout from '../layouts/PageLayout';
import TopCover from '../components/HomePage/TopCover';
import Visionary from '../components/HomePage/Visionary';
import SubmitQuote from '../components/SubmitQuote/index';
import InnovativePlatforms from '../components/HomePage/InnovativePlatforms';
import ThroughYears from '../components/HomePage/ThroughYears';
import Projects from '../components/HomePage/Projects';
import ValuedClients from '../components/HomePage/ValuedClients';
import ProgrammingHub from '../components/ProgrammingHub';
import ExpertProgrammers from '../components/HomePage/ExpertProgrammers';
import Preloader from '../components/Preloader';
import { useHeroPictureHome } from '../hooks/use-hero-picture';
import { home } from '../seoConfig';

const IndexPage = ({ location }) => {
	const img = useHeroPictureHome();
	return (
		<PageLayout location={location} seoConfig={home}>
			<Preloader />
			<TopCover image={img} altText="pixelteh building outside" />
			<Visionary />
			<SubmitQuote />
			<InnovativePlatforms />
			<ThroughYears />
			<Projects />
			<ValuedClients />
			<ExpertProgrammers />
			<ProgrammingHub />
		</PageLayout>
	);
};

IndexPage.propTypes = {
	location: PropTypes.object,
};

// export const query = heroImage;
// export const query = graphql`
//   query {
//     file(relativePath: { eq: "intro-bg.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1920) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;

export default IndexPage;
