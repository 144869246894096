import styled from 'styled-components';
import H3 from '../../_common/H3';
import { colors, media } from '../../../globalStyles';

const StyledPlatformItem = styled.div`
	${H3} {
		pointer-events: none;
	}
	${media.maxXl`
		width: 100%;
		margin: 0;
		margin-bottom: 40px;
		.svg-container{
			display: none;
		}
		${H3} {
			text-align: center;
			display: block;
		}
	`}
	${media.maxSm`
		${H3}{
			&:before{
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				background-color: ${colors.orange};
				border-radius: 100%;
				margin: 0 auto 20px;
			}
		}
	`}
`;
export default StyledPlatformItem;
