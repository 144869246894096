import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H2 from '../../_common/H2';
import H5 from '../../_common/H5';
import { colors, fonts, fontSizes, media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';

const StyledProjectItem = styled.div`
	width: 30%;

	${media.xxl`
		width: 444px;
	`};

	${media.maxSm`
		.description ${H5} {
			display: none;
		}
	`};

	@media (max-width: 1125px) {
		width: 100%;
		max-width: 400px;
		margin: 0 auto 87px;
	}

	@media (max-width: 460px) {
		margin: 0 auto 30px;
		&&& .block-wrapper {
			margin-bottom: 25px;
		}

		${H2} a {
			font-size: 28px;
		}

		${H5} a {
			font-size: ${fontSizes.p};
		}
	}

	.block-wrapper {
		display: block;
		width: 100%;
		margin-bottom: 68px;
		text-decoration: none;
		.img {
			width: 100%;
		}
	}

	.project-link {
		color: inherit;
		text-decoration: none !important;
		position: relative;
		font-family: ${fonts.robotoRegular};
		font-weight: 700;
		font-size: 36px;

		&:after {
			content: '';
			height: 1px;
			background-color: ${colors.blackTitle};
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&:hover {
			&:after {
				display: none;
			}
		}
	}

	.description {
		${H2}, ${H5} {
			text-align: center;
		}
		${H2} {
			font-size: 36px;
			margin-bottom: 27px;
		}
		${H5} {
			font-family: ${fonts.robotoLightItalic};
			color: ${colors.greyItalicTitle};
		}
	}
`;
const ProjectItem = ({ name, description, children, linkTo }) => {
	return (
		<StyledProjectItem>
			<AnimatedOnScroll animateIn="zoomInUp" animateOut="zoomOutUp">
				<a className="block-wrapper" href={linkTo}>
					{children}
				</a>

				<div className="description">
					<H2>
						<a
							className="project-link"
							href={linkTo}
							target="_blank"
							rel="noopener noreferrer"
						>
							{name}
						</a>
					</H2>

					<H5>{description}</H5>
				</div>
			</AnimatedOnScroll>
		</StyledProjectItem>
	);
};

ProjectItem.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	img: PropTypes.string,
	children: PropTypes.any,
	linkTo: PropTypes.string,
};

export default ProjectItem;
