import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors, media } from '../../../globalStyles';
import bitcoinVideo from '../../../assets/video/bitcoin-video.mp4';
import useRequestIdleCallback from '../../../hooks/use-request-idle-callback';

const StyledVideo = styled.div`
	border: 17px solid ${colors.grey};
	position: absolute;
	left: 50%;
	top: 77px;
	transform: translateX(-50%);
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	width: 384px;
	height: 383px;
	${media.xxl`
		width: 454px;
		height: 453px;
	`}
	${media.maxXl`
		display: none;
	`}
	&:after {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(12, 49, 97, 0.7);
		content: '';
		display: block;
		left: 0;
		top: 0;
	}
	video {
		height: 101%;
		display: block;
		position: absolute;
		top: 0;
		left: auto;
		right: auto;
	}
`;

const Video = () => {
	const videoEl = useRef(null);
	useRequestIdleCallback(() => {
		if (window.innerWidth > 1365) {
			videoEl.current.src = bitcoinVideo;
			videoEl.current.load();
		}
	});
	return (
		<StyledVideo>
			<video
				ref={videoEl}
				autoPlay="autoplay"
				muted="muted"
				loop="loop"
				preload="none"
			/>
		</StyledVideo>
	);
};

export default Video;
