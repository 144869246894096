import React from 'react';
import styled from 'styled-components';
import line4 from '../../../assets/svg/line-4.svg';
import Title from './Title';
import Text from './Text';
import { media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';
import StyledPlatformItem from './PlatformItem';

const StyledAI = styled(StyledPlatformItem)`
	width: 351px;
	margin-top: 132px;
	${media.xxl`
		margin-top: 177px;
	`}
	.svg-container {
		bottom: 23%;
		left: 105%;
		padding: 0;
		width: 144%;
	}
`;
const AI = () => (
	<StyledAI>
		<AnimatedOnScroll animateIn="fadeInRight" animateOut="fadeOutRight">
			<Title Line={line4}>
				Artificial Intelligence &amp; <br /> Machine Learning
			</Title>
			<Text linkTo="/technologies-machine-learning">
				Make a smart choice with AI and ML! Google, Facebook, Amazon, and even
				small businesses are using artificial intelligence and machine learning
				to improve their products and services – and drive profits!
			</Text>
		</AnimatedOnScroll>
	</StyledAI>
);

export default AI;
