import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
// import rambleLogo from '../../../assets/img/ramble-logo.png';
import { fonts, media } from '../../../globalStyles';
import { graphql, useStaticQuery } from 'gatsby';

const StyledRamble = styled.div`
	background-color: #925c9f;
	padding: 29px 46px 28px 42px;
	font-family: ${fonts.muli};
	height: 579px;
	.img-wrapper {
		max-width: 80px;
		margin: 0 auto 40px;
	}
	.chat-block {
		&-white {
			background-color: #fff;
			padding: 13px 30px 12px 13px;
			font-size: 20px;
			color: #434343;
			position: relative;
			margin-bottom: 17px;
			border-radius: 3px;
			&:after {
				content: '';
				position: absolute;
				right: -10px;
				top: 16px;
				border: 4px solid transparent;
				border-left-width: 4px;
				border-left-style: solid;
				border-left-color: transparent;
				border-left: 7px solid #fff;
			}
		}
		&-purple {
			background: rgba(178, 140, 187, 0.95);
			color: #fff;
			padding: 7px 0 8px 13px;
			font-size: 13px;
			line-height: 20px;
			position: relative;
			margin-bottom: 22px;
			border-radius: 3px;
			&:after {
				content: '';
				position: absolute;
				left: -11px;
				top: 37%;
				border: 4px solid transparent;
				border-right-width: 4px;
				border-right-style: solid;
				border-right-color: transparent;
				border-right: 7px solid rgba(255, 255, 255, 0.3);
			}
		}
	}
	.send-btn {
		border: 1px solid #fff;
		border-radius: 2px;
		line-height: 32px;
		min-height: 35px;
		background: transparent;
		//margin: 0 10px;
		position: relative;
		overflow: hidden;
		color: #fff;
		text-align: right;
		padding-right: 25px;
		font-size: 12px;
		font-weight: 700;
	}
	${media.maxXl`
		height: 543px;
	`}
	@media(min-width: 1126px) and (max-width: 1242px) {
		padding-left: 25px;
		padding-right: 25px;
		.chat-block {
			&-purple {
				font-size: 12px;
			}
			&-white {
				font-size: 16px;
			}
		}
	}
	@media (max-width: 388px) {
		padding-left: 25px;
		padding-right: 25px;
		.chat-block-white {
			font-size: 17px;
		}
	}
`;

const Ramble = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "ramble-logo.png" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return (
		<StyledRamble>
			<div className="img-wrapper">
				<Img
					fluid={data.file.childImageSharp.fluid}
					title="ramble-cover"
					alt="ramble-cover"
				/>
			</div>
			<div className="chat-block-white">
				<span>Why all the hype?</span>
			</div>
			<div className="chat-block-purple">
				<span>We are revolutionizing digital engagement.</span>
			</div>
			<div className="chat-block-white">
				<span>How are we different?</span>
			</div>
			<div className="chat-block-purple">
				<span>
					Powerful new chat technology that can embed anywhere online!
				</span>
			</div>
			<div className="chat-block-white">
				<span>Real-time engagement?</span>
			</div>
			<div className="send-btn">
				<span>SEND.</span>
			</div>
		</StyledRamble>
	);
};

export default Ramble;
