import styled from 'styled-components';

const StyledFullHeightSection = styled.section`
	height: 100vh;
	width: 100%;
	display: flex;
	position: relative;
	min-height: 667px;
`;

export default StyledFullHeightSection;
