import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H3 from '../../_common/H3';
import { fonts, fontSizes } from '../../../globalStyles';

const StyledTitle = styled(H3)`
	font-family: ${fonts.robotoRegular};
	font-size: ${fontSizes.h3};
	font-weight: 700;
	position: relative;
	display: inline-block;
	margin-bottom: 23px;
	.svg-container {
		display: inline-block;
		position: absolute;
		width: 100%;
		padding-bottom: 100%;
		vertical-align: middle;
		overflow: hidden;
	}
	.line {
		position: relative;
		z-index: 4;
	}
	@media (max-width: 1200px) {
		font-size: 22px;
	}
`;
const Title = ({ Line, children }) => (
	<StyledTitle>
		<div className="svg-container">
			<Line />
		</div>
		{children}
	</StyledTitle>
);
Title.propTypes = {
	children: PropTypes.any,
	Line: PropTypes.any,
};
export default Title;
