import React from 'react';
import styled from 'styled-components';
import line1 from '../../../assets/svg/line-1.svg';
import Title from './Title';
import Text from './Text';
// import { media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';
import StyledPlatformItem from './PlatformItem';

const StyledBlockchain = styled(StyledPlatformItem)`
	width: 360px;
	.svg-container {
		top: 10px;
		left: 100%;
		width: 450px;
	}
`;
const Blockchain = () => (
	<StyledBlockchain>
		<AnimatedOnScroll animateIn="fadeInRight" animateOut="fadeOutRight">
			<Title as="h3" Line={line1}>
				Blockchain &amp; <br /> Cryptocurrencies
			</Title>
			<Text linkTo="/technologies-blockchain">
				Get ahead of your rivals by stepping into the future of fast, lucrative,
				and secure transactions. Our blockchain and cryptocurrency development
				company builds applications for any industry.
			</Text>
		</AnimatedOnScroll>
	</StyledBlockchain>
);

export default Blockchain;
