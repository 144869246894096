import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H3 from '../../_common/H3';
import { colors, fonts, media } from '../../../globalStyles';
import ScrollAnimation from 'react-animate-on-scroll';

const StyledSquare = styled(ScrollAnimation)`
	width: 280px;
	height: 283px;
	//padding: 68px 0 70px;
	position: relative;
	animation: 1s ease-in-out fadeIn;
	display: flex;
	${media.maxMd`
		width: 210px;
		height: 210px;
		&:not(:last-child){
			margin-right: 40px;
		}
	`}
	&:before,
	&:after {
		border: 3px solid transparent;
		box-sizing: inherit;
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}
	&:before {
		top: -1px;
		left: -1px;
	}
	&:after {
		bottom: 0;
		right: 0;
	}
	&.drawIn {
		&:before,
		&:after {
			width: 100%;
			height: 100%;
		}
		&:before {
			border-top-color: ${colors.orange};
			border-right-color: ${colors.orange};
			transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
		}
		&:after {
			border-bottom-color: ${colors.orange};
			border-left-color: ${colors.orange};
			transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
				height 0.25s ease-out 0.75s;
		}
	}
	${H3} {
		text-transform: uppercase;
		font-family: ${fonts.openSansSembiBold};
		text-align: center;
		color: ${colors.blackTitle};
		font-size: 28px;
		line-height: 42px;
		word-break: break-word;
		${media.maxMd`
			font-size: 22px;
			line-height: 36px;
		`}
	}
	.text-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
`;
const Square = ({ subtitle }) => {
	return (
		<StyledSquare animateIn="drawIn">
			<div className="text-wrapper">
				<H3 dangerouslySetInnerHTML={{ __html: subtitle }} />
			</div>
		</StyledSquare>
	);
};

Square.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

const StyledSquares = styled.div`
	display: flex;
	justify-content: space-around;
	margin-bottom: 95px;
	${media.maxMd`
		margin-bottom: 35px;
	`}
`;

const Squares = ({ items }) => (
	<StyledSquares>
		{items.map(item => (
			<Square key={item.subtitle} {...item} />
		))}
	</StyledSquares>
);

Squares.propTypes = {
	items: PropTypes.array,
};

export default Squares;
