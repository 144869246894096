import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../../globalStyles';

const StyledValuedClientsRow = styled.div`
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent};
	padding: ${({ padding }) => padding};
	position: relative;
	${({ line }) => {
		if (line === 1) {
			return css`
				max-width: 1736px;
				padding-right: 65px;
				padding-left: 22px;
				@media (max-width: 1200px) {
					display: none;
				}
			`;
		}
		if (line === 2) {
			return css`
				@media (max-width: 1200px) {
					max-width: 804px;
					margin: 60px auto 0;
				}
				${media.maxMd`
					max-width: 100%;
					margin: 0;
				`}
			`;
		}
	}}
	${media.maxMd`
		flex-direction: column;
		align-items: center;
	`}
`;
StyledValuedClientsRow.defaultProps = {
	justifyContent: 'space-around',
};
const ValuedClientsRow = ({ children, justifyContent, padding, line }) => {
	return (
		<StyledValuedClientsRow
			line={line}
			padding={padding}
			justifyContent={justifyContent}
		>
			{children}
		</StyledValuedClientsRow>
	);
};

ValuedClientsRow.propTypes = {
	children: PropTypes.any,
	line: PropTypes.number,
	justifyContent: PropTypes.string,
	padding: PropTypes.string,
};

export default ValuedClientsRow;
