import React from 'react';
import styled from 'styled-components';
import line3 from '../../../assets/svg/line-3.svg';
import Title from './Title';
import Text from './Text';
import { media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';
import StyledPlatformItem from './PlatformItem';

const StyledAndroid = styled(StyledPlatformItem)`
	margin-top: 76px;
	width: 294px;
	${media.xxl`
		margin-top: 112px;
		width: 366px;
	`}
	.svg-container {
		top: 0;
		right: 108%;
		width: 268px;
	}
`;
const Android = () => (
	<StyledAndroid>
		<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
			<Title Line={line3}>Android Programming</Title>
			<Text linkTo="/technologies-android">
				The world runs on apps now. Has your business carved out a niche in the
				app market? If not, you are missing out. Take advantage of the explosive
				growth of mobile devices. Don’t miss out on the mobile revolution.
			</Text>
		</AnimatedOnScroll>
	</StyledAndroid>
);

export default Android;
