import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H1 from '../_common/H1';
import H2 from '../_common/H2';
import StyledFullHeightSection from '../_common/FullHeightSection';
import { colors, fonts, fontSizes, media } from '../../globalStyles';
import video from '../../assets/video/pixelteh.mp4';
import ScrollForMore from '../ScrollForMore';
import CoverImg from '../_common/CoverImg';

const StyledTopCover = styled.div`
	background-size: cover;
	position:relative;
	.gatsby-image-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
	.video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		overflow: hidden;
		align-items: center;
		${media.maxXl`
    	display: none;
    `}
		video {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			min-height: 100%;
			min-width: 100%;
			width: auto;
			height: auto;
			max-width: 160%;
		}
	}
	.top-cover-text-wrapper {
		text-align: center;
		display: inline-block;
		position: relative;
		z-index: 2;
		svg {
			position: absolute;
			left: -128px;
			top: 27px;
			@media(max-width: 1200px){
				display: none;
			}
		}
	}
	${H1} {
		font-size: ${fontSizes.topCoverTitle};
		color: ${colors.white};
		font-weight: 500;
		font-family: ${fonts.robotoMedium};
		span{
			display: block;
			margin-top: 50px;
			font-family: ${fonts.robotoMedium};
			font-size: ${fontSizes.topCoverSubtitle};
			color: ${colors.orange};
		}
	}
	${H2} {
		font-family: ${fonts.robotoMedium};
		font-size: ${fontSizes.topCoverSubtitle};
		color: ${colors.orange};
		font-weight: 500;
	}
	${StyledFullHeightSection} {
		justify-content: center;
		align-items: center;
		&:before {
			background-color: rgba(12, 49, 97, 0.7);
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: block;
			z-index: 1;
		}
	}
	${media.xl`
		&.loaded-video{
			background: none;
		}
	`}
	${media.maxSm`
		${H1}{
			font-size: 30px;
			margin-bottom: 17px;
		}
		${H2} {
			font-size: 42px;
			line-height: 42px;
		}
	`}
	${media.maxXs`
		${H2} {
			font-size: 28px;
		}
	`}
`;

class TopCover extends React.Component {
	state = {
		loadedVideo: false,
	};
	handleVideoLoad = () => {
		const body = document.querySelector('body');
		setTimeout(() => {
			body.classList.add('hiding-preloader');
			setTimeout(() => {
				body.classList.remove('show-preloader', 'hiding-preloader');
			}, 1600);
		}, 1000);
	};

	componentDidMount() {
		if (window.innerWidth >= 1365) {
			this.video.src = video;
			this.video.addEventListener('loadeddata', this.handleVideoLoad, false);
			this.video.load();
			this.setState({ loadedVideo: true });
		} else {
			this.handleVideoLoad();
		}
	}

	getRef = el => {
		if (el) {
			this.video = el;
		}
	};

	render() {
		return (
			<StyledTopCover className={this.state.loadedVideo ? 'loaded-video' : ''}>
				<StyledFullHeightSection>
					<CoverImg
						style={{ position: 'absolute' }}
						fluid={this.props.image}
						title={this.props.altText}
					/>
					<div className="video-wrapper">
						<video ref={this.getRef} autoPlay muted="muted" loop="loop" />
					</div>
					<div className="top-cover-text-wrapper">
						<H1>
							Engineering the Future of <br />
							<span>Immersive Technology</span>
						</H1>
						<svg
							id="svg-line"
							width="280"
							height="119.99999999999999"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								id="line1"
								fillOpacity="null"
								strokeWidth="0.7"
								stroke="#ffffff"
								fill="none"
								d="M0.200041,0.75L100.400025,0.75"
								style={{ strokeDasharray: '101, 103', strokeDashoffset: '0' }}
							/>
							<path
								id="line2"
								fillOpacity="null"
								strokeOpacity="null"
								strokeWidth="0.7"
								stroke="#ffffff"
								fill="none"
								d="M0.75,1.260015L0.75,107"
							/>
							<path
								id="line3"
								fillOpacity="null"
								strokeOpacity="null"
								strokeWidth="0.7"
								stroke="#ffffff"
								fill="none"
								d="M-0.199959,107.250003L189.599964,107.24991"
							/>
							<path
								id="line4"
								fillOpacity="null"
								strokeOpacity="null"
								strokeWidth="0.7"
								stroke="#ffffff"
								fill="none"
								d="M186.599966,102.859986L189.799964,106.859985"
								style={{ strokeDasharray: '6, 8', strokeDashoffset: '0' }}
							/>
							<path
								id="line5"
								fillOpacity="null"
								strokeOpacity="null"
								strokeWidth="0.7"
								stroke="#ffffff"
								fill="none"
								d="M189.799964,107.259985L186.399965,111.059984"
								style={{ strokeDasharray: '6, 8', strokeDashoffset: '0' }}
							/>
						</svg>
					</div>
					<ScrollForMore />
				</StyledFullHeightSection>
			</StyledTopCover>
		);
	}
}

TopCover.propTypes = {
	image: PropTypes.object,
	altText: PropTypes.string,
};

export default TopCover;
