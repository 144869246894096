import React from 'react';
import styled from 'styled-components';
import P from '../_common/P';
import PageDescription from '../PageDescription';

const ExtendedP = styled(P)`
	font-size: 18px;
`;
const Visionary = () => (
	<PageDescription
		title="Web & Mobile App Programming"
		subtitle="for Tomorrow’s World"
		headerType="big"
		paddingBottom={53}
		showBottomBtn
		linkTo="/technologies"
	>
		<ExtendedP as="h2">
			<b>Harness the power and impact of immersive technology.</b>
		</ExtendedP>
		<ExtendedP>
			<b>PixelTeh</b> is a top mobile app and web development company that is
			improving the world – one line of code at a time. As innovative and
			experienced programmers, more than 55 strong, we design, develop, and
			deploy technological solutions for augmented reality, virtual reality,
			artificial intelligence, and much more. If you can dream it, we can build
			it!
			<br />
			<br />
		</ExtendedP>
		<ExtendedP className="hidden-xs">
			Be an industry leader by letting us design a custom platform that
			captivates your customers. From high-tech VR and AR games to
			transformative mobile apps for businesses, there are so many new ways to
			redefine the end-user’s app experience. Whether you want to create
			excitement over your products or you need a new website, you shouldn’t
			settle for the old way of doing things. It just won’t work in today’s
			modern world.
		</ExtendedP>
		<ExtendedP as="h2">
			<b>
				This is the era of user experience. <br /> And PixelTeh is your trusted
				partner for digital transformation.
			</b>
		</ExtendedP>
	</PageDescription>
);

export default Visionary;
