import { graphql, useStaticQuery } from 'gatsby';

export const childImageFragment = graphql`
	fragment ChildImageSharpFluid on File {
		childImageSharp {
			fluid(maxWidth: 1920, quality: 80) {
				...GatsbyImageSharpFluid_withWebp
			}
		}
	}
`;
export const useHeroPictureHome = () => {
	// const image = data.file.childImageSharp.fluid
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "intro-bg.png" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return data.file.childImageSharp.fluid;
};
