import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';

// Styles
import { fonts, media } from '../../../globalStyles';

//----------------------------------------------------------------------------------------------------------------------

const CellWrapper = styled.div`
	display: flex;
	flex: 25%;
	margin: -73px 0 0 0;
	${({ reverse }) => {
		if (reverse) {
			return css`
				flex-direction: row-reverse;
			`;
		}
	}}
	.cell-image, .cell-desc {
		//max-width: 230px;
		flex: 50%;
	}
	.cell-image {
		position: relative;
		z-index: 1;
		overflow: hidden;
	}
	.cell-image img {
		width: 101%;
		transition: all 0.5s!important;
	}
	.cell-desc {
		position: relative;
	}
	.cell-desc div {
		position: absolute;
		bottom: 93px;
		padding: 0 15px;
		transition: bottom 0.5s;
	}
	.cell-desc .name {
		font: 400 16px/20px ${fonts.loveloBlack}
	}
	.cell-desc .position {
		color: #919191;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
	}
	${media.minMd`
		&:hover {
			.cell-image img {
				transform: scale(1.1);
			}
			.cell-desc div {
				bottom: 113px;
			}
		}
	`}
	${media.maxMd`
		width: 200px;
	`}
	${media.maxSm`
		margin: 0 10px;
	`}
	${({ descriptionBg }) => {
		return css`
			.cell-desc {
				background: ${descriptionBg};
			}
		`;
	}}
	${({ bottomRow }) => {
		if (bottomRow) {
			return css`
				.cell-desc div {
					bottom: 30px;
				}
				${media.minMd`
					&:hover {
						.cell-desc div {
							bottom: 50px;
						}
					}
				`}
			`;
		}
	}}
	${media.laptop`flex: 30%;`}
	${media.maxMd`
		flex: none;
		display: block;
		margin: 0;
		.cell-desc{
			background: none;
			padding: 10px;
			div{
				position: relative;
				bottom: 0;
				text-align: center;
			}
		}
		.cell-image{
			img{
				width: 180px;
				margin: 0 auto;
			}
		}
	`}
`;

const Cell = ({ programmer, bottomRow, reverse }) => (
	<CellWrapper
		bottomRow={bottomRow}
		descriptionBg={programmer.descriptionBg}
		reverse={reverse}
	>
		<div className={'cell-image'}>
			<Img alt={programmer.name} fluid={programmer.image} />
		</div>
		<div className={'cell-desc'}>
			<div>
				<span className={'name'}>{programmer.name}</span>
				<br />
				<span className={'position'}>{programmer.position}</span>
			</div>
		</div>
	</CellWrapper>
);

Cell.propTypes = {
	programmer: PropTypes.object,
	descriptionBg: PropTypes.string,
	bottomRow: PropTypes.bool,
	reverse: PropTypes.bool,
};

export default Cell;
