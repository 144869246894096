import React from 'react';

// Components
import ContentSection from '../../ContentSection';
import ProgrammersChessboard from './ProgrammersChessboard';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';

//----------------------------------------------------------------------------------------------------------------------

const ExpertProgrammers = () => (
	<ContentSection
		disablePadding
		fluid
		title={'Expert Web & Mobile Application Programmers at Your Service'}
		subtitle={
			'More than 55 talented programming specialists, developers, & project managers!'
		}
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<ProgrammersChessboard />
		<CenterAnimatedButton
			gaLabel="home-page See the team page"
			animateOut="fadeOut"
			animateIn="fadeIn"
			linkTo="/team"
		>
			See the team page
		</CenterAnimatedButton>
	</ContentSection>
);

export default ExpertProgrammers;
