import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import P from '../../_common/P';
import arrowIcon from '../../../assets/img/arrow-sprite.png';
import { media } from '../../../globalStyles';

const StyledArrowLink = styled(Link)`
	width: 23px;
	height: 11px;
	background: url(${arrowIcon}) -53px -10px;
	display: inline-block;
	margin-left: 15px;
`;

const StyledText = styled(P)`
	line-height: 2;
	${media.maxSm`
		font-style: italic;
    color: #82888d;
	`}
`;

const Text = ({ children, linkTo }) => (
	<StyledText>
		{children}
		<StyledArrowLink to={linkTo} />
	</StyledText>
);
Text.propTypes = {
	children: PropTypes.any,
	line: PropTypes.any,
	linkTo: PropTypes.string,
};
export default Text;
