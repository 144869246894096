import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import P from '../../_common/P';
import { fonts, colors, media } from '../../../globalStyles';

const boxSuiteStyles = css`
	max-width: 226px;
	order: 2;
	&:after,
	&:before {
		top: 25px;
		left: 24px;
	}
	&:after {
		height: 102px;
	}
	&:before {
		width: 44px;
	}
	.circle {
		width: 99px;
		height: 99px;
		background-color: #405c82;
		top: -36px;
		right: -43px;
	}
	.img {
		margin-left: auto;
	}
	${P} {
		margin-top: 103px;
	}
`;
const homeHelloStyles = css`
	max-width: 278px;
	order: 4;
	@media (max-width: 1200px) {
		order: 2;
	}
	&:after,
	&:before {
		left: 20px;
	}
	&:after {
		top: 59px;
		height: 32px;
	}
	&:before {
		top: 91px;
		width: 38px;
	}
	${P} {
		margin-left: 64px;
		margin-top: 40px;
	}
`;
const s8PropertyStyles = css`
	order: 1;
	max-width: 301px;
	margin-top: -57px;
	@media (max-width: 1200px) {
		order: 3;
	}
	@media (max-width: 1065px) {
		${P} {
			padding-left: 15px;
		}
	}
	/* margin-top: 115px; */
	&:after,
	&:before {
		left: 20px;
		bottom: 22px;
	}
	&:after {
		height: 143px;
	}
	&:before {
		width: 44px;
	}
	${P} {
		margin-top: 129px;
		margin-left: 75px;
	}
`;
const moveriaStyles = css`
	max-width: 229px;
	/* margin-top: 169px; */
	order: 3;
	@media (max-width: 1200px) {
		order: 4;
	}
	&:after,
	&:before {
		left: 54px;
		top: 22px;
	}
	&:after {
		height: 97px;
	}
	&:before {
		width: 30px;
	}
	.circle {
		top: -110px;
		left: -10px;
		width: 185px;
		height: 185px;
		background-color: #2a466c;
	}
	.img {
		margin-left: auto;
	}
	${P} {
		margin-top: 96px;
		margin-right: 25px;
	}
`;
const openDriveStyles = css`
	max-width: 329px;
	margin-top: 22px;
	order: 3;
	@media (max-width: 1200px) {
		order: 0;
	}
	&:after,
	&:before {
		left: 13px;
		top: 31px;
	}
	&:after {
		height: 44px;
	}
	&:before {
		width: 70px;
	}
	.circle {
		width: 141px;
		height: 141px;
		background-color: #405c82;
		right: -57px;
		top: -30px;
	}
	.img {
		margin-left: auto;
	}
	${P} {
		margin-right: 103px;
		margin-top: 38px;
	}
`;
const pleyStyles = css`
	max-width: 437px;
	&:after,
	&:before {
		left: 152px;
		top: 40px;
	}
	&:after {
		height: 65px;
	}
	&:before {
		width: 137px;
	}
	.img {
		margin-left: auto;
	}
	${P} {
		margin-right: 211px;
		margin-top: 31px;
	}
`;
const mobelioStyles = css`
	max-width: 378px;
	&:after,
	&:before {
		left: 45px;
	}
	&:after {
		height: 49px;
		top: 58px;
	}
	&:before {
		top: 106px;
		width: 95px;
	}
	${P} {
		margin-top: 37px;
		margin-left: 152px;
	}
`;

const StyledValuedClient = styled.div`
	position: relative;
	&:before,
	&:after {
		content: '';
		background: ${colors.blueGrey};
		position: absolute;
		display: block;
		z-index: 2;
	}
	&:after {
		width: 1px;
	}
	&:before {
		height: 1px;
	}
	.circle {
		z-index: 1;
		position: absolute;
		border-radius: 100%;
	}
	.img {
		display: block;
		z-index: 2;
		position: relative;
	}
	${P} {
		font-family: ${fonts.openSans};
		color: #c3d3e9;
		font-size: 13px;
		font-style: italic;
		line-height: 18px;
		z-index: 2;
	}
	${({ hiddenSm }) => {
		return (
			hiddenSm &&
			css`
				@media (max-width: 1200px) {
					display: none;
				}
			`
		);
	}}
	${({ visibleSm }) => {
		return (
			visibleSm &&
			css`
				display: none;
				@media (max-width: 1200px) {
					display: block;
				}
			`
		);
	}}
	${({ name }) => {
		switch (name) {
			case 'boxSuite':
				return boxSuiteStyles;
			case 'moveria':
				return moveriaStyles;
			case 's8property':
				return s8PropertyStyles;
			case 'homeHello':
				return homeHelloStyles;
			case 'openDrive':
				return openDriveStyles;
			case 'pley':
				return pleyStyles;
			case 'mobelio':
				return mobelioStyles;
			default:
				return null;
		}
	}}
	@media (max-width: 1200px){
		max-width: 247px;
		margin: 0;
		&:before, &:after{
			display: none;
		}
		.img-wrapper {
			height: 100px;
			text-align: center;
		}
		.img {
			margin: 0;
			display: inline-block;
		}
		${P} {
			margin: 0;
		}
		.circle{
			display: none;
		}
	}
	${media.maxMd`
		max-width: 100%;
		border-bottom: 1px solid #40628e;
    margin-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    ${({ name }) =>
			name === 'mobelio' &&
			css`
				border-bottom: none;
			`}
    ${P} {
    	text-align: center;
    	margin-bottom: 50px;
    }
	`}
`;
const ValuedClientItem = ({ img, text, name, hiddenSm, visibleSm, alt }) => {
	return (
		<StyledValuedClient name={name} hiddenSm={hiddenSm} visibleSm={visibleSm}>
			<div className="img-wrapper">
				<img src={img} alt={alt} className="img" />
			</div>

			<P>{text}</P>

			<span className="circle" />
		</StyledValuedClient>
	);
};

ValuedClientItem.propTypes = {
	img: PropTypes.string,
	text: PropTypes.string,
	name: PropTypes.string,
	hiddenSm: PropTypes.bool,
	visibleSm: PropTypes.bool,
	alt: PropTypes.string,
};

export default ValuedClientItem;
