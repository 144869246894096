import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import Squares from './Squares';
import YearsScale from './YearsScale';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';
import StyledTextContainer from '../../_common/TextContainer';
import P from '../../_common/P';
import H5 from '../../_common/H5';
import { colors, fonts } from '../../../globalStyles';

const years = {
	2007: [
		{
			subtitle: 'two guys <br /> FREELANCING <br /> FROM HOME',
		},
		{
			subtitle: 'NO OFFICE<br /> NO PORTFOLIO<br /> NO TEAM',
		},
		{
			subtitle: 'First IT OUTSOURCING PROJECT',
		},
	],
	2008: [
		{
			subtitle: 'Successful DELIVERY FOR U.S. COMPANY',
		},
		{
			subtitle: 'New OFFICE <br> 850 SQ FT',
		},
		{
			subtitle: '6 NEW DEVELOPERS ON BOARD',
		},
	],
	2011: [
		{
			subtitle:
				'Launched <br class="visible-md"> MOBILE PROGRAMMING DEPARTMENT',
		},
		{
			title: 'Top Rated',
			subtitle: 'Top Rated COMPANY AT UPWORK',
		},
		{
			subtitle: 'Larger OFFICE <br> 4, 300 SQ FT',
		},
	],
	2015: [
		{
			subtitle: 'Began HANDING LARGE-SCALE PROJECTS',
		},
		{
			subtitle: 'Changed FROM PHP TO PYTHON',
		},
		{
			subtitle: 'English COURSES PROVIDED AT COMPANY',
		},
	],
	2017: [
		{
			subtitle: '55 EMPLOYEES & NEW PROJECT MANAGERS',
		},
		{
			subtitle:
				'Big Office <br /> 10,700 SQ FT <br /> TO BETTER SERVE OUR CLIENTS',
		},
		{
			subtitle: 'AI/ML <br /> BLOCKCHAIN & CRYPTOCURRENCY <br /> DEPARTMENTS',
		},
	],
};
const yearsLabels = Object.keys(years);
const StyledWrapper = styled.div`
	max-width: 1520px;
	margin: 0 auto;
	padding: 0 15px;
	${H5} {
		font-weight: 700;
	}
	${P} {
		margin: 22px 0;
		a {
			text-decoration: none;
			color: ${colors.blackTitle};
			font-family: ${fonts.openSansBold};
		}
	}
`;

const ThroughYears = () => {
	const [currentYear, setYear] = useState('2007');
	const squares = years[currentYear];
	return (
		<ContentSection
			hiddenSm
			fluid
			title="PixelTeh Through The Years"
			subtitle="Always Creating, Improving, &amp; Fulfilling"
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<StyledWrapper>
				<Squares items={squares} />
				<YearsScale
					onChangeYear={setYear}
					currentYear={currentYear}
					years={yearsLabels}
				/>
				<StyledTextContainer paddingBottom={108}>
					<H5>
						PixelTeh had humble beginnings — 2 guys, 1 project, and no office.
					</H5>
					<P>
						Due to our strong work ethic and our commitment to delivering the
						best results, our business grew exponentially. In less than one
						year, we went from 2 guys to 6 developers. Ten years later, we have
						more than 55 employees that consists of project managers, front-end
						developers, back-end developers, and
						<Link to={'/technologies-full-stack'}> full-stack specialists</Link>
						. Now, we offer a wide range of programming options for immersive
						technology, machine learning, blockchain, and all other modern
						platforms.
					</P>
					<H5>Contact us to see what we can do for you and your business!</H5>
				</StyledTextContainer>
				<CenterAnimatedButton
					animateIn="zoomIn"
					animateOut="zoomOut"
					linkTo="/team"
					gaLabel="home-page See team page"
				>
					See team page
				</CenterAnimatedButton>
			</StyledWrapper>
		</ContentSection>
	);
};

ThroughYears.propTypes = {};

export default ThroughYears;
