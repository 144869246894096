import React from 'react';
import styled from 'styled-components';
import line2 from '../../../assets/svg/line-2.svg';
import Title from './Title';
import Text from './Text';
import StyledPlatformItem from './PlatformItem';
import { media } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';

const StyledIos = styled(StyledPlatformItem)`
	margin-right: 4%;
	width: 406px;
	${media.xxl`
		margin-right: 16%;
	`}
	.svg-container {
		top: 10px;
		right: 111%;
		width: 172px;
	}
`;
const Ios = () => (
	<StyledIos>
		<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
			<Title Line={line2}>IOS Development</Title>
			<Text linkTo="/technologies-ios">
				At <b>PixelTeh</b>, we know the perfect way to accelerate your business.
				Even though a big budget and creative ideas can get you far, you won’t
				really stand out in the digital world without a customized iPhone app.
				Let us accelerate, transform, and innovate your business.
			</Text>
		</AnimatedOnScroll>
	</StyledIos>
);

export default Ios;
